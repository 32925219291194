<script>
import Autocomplete from '@/components/general/Autocomplete'
import ImageUpload from '@/components/general/ImageUpload'
import InputField from '@/components/general/InputField'
import TextArea from '@/components/general/TextArea'
import SelectField from '@/components/general/SelectField'
import ConfirmationModal from '@/components/general/ConfirmationModal'
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
export default {
  name: 'ProfileForm',
  components: {
    TextArea,
    InputField,
    ImageUpload,
    Autocomplete,
    SelectField,
    ConfirmationModal
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      lastName: {
        required: () => {
          return required || this.isBusiness
        }
      },
      resume: {
        maxLength: maxLength(1000)
      },
      links: {
        $each: {
          url: (val) => {
            if (!val || !val.value) return true
            const expression = `${val.type !== 'url' ? val.type : '\\w*'}[.][a-zA-Z]{1,6}${val.type !== 'url' ? '/\\w*' : ''}`
            const regex = new RegExp(expression, 'gi')
            return !!val.value.match(regex)
          }
        }
      },
      imageUrl: {
        fileSize: helpers.withParams({
          size: 20971520
        },
        function (image) {
          if (!image || (this.formDataInitialState && image === this.formDataInitialState.imageUrl)) return true
          return this.isValidBlobSize(image, 20)
        })
      }
    }
  },
  props: {
    getSelectedWorkspace: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSaving: false,
      skillSearch: '',
      locationSearch: '',
      locationOptions: [],
      formData: {
        name: null,
        lastName: null,
        position: null,
        skills: null,
        resume: null,
        location: null,
        company: null,
        imageUrl: null,
        activitySector: null,
        links: []
      },
      formDataInitialState: null,
      maxSkills: 3,
      availableSkills: [],
      showConfirmationModal: false,
      dirtyForm: false,
      nextPageFunction: null
    }
  },
  watch: {
    'formData.skills.length' (newLength) {
      if (newLength > this.maxSkills) {
        this.formData.skills.pop()
      }
    },
    formData: {
      handler () {
        if (this.formDataInitialState && !this.objIsEmpty(this.formDataInitialState)) {
          this.$emit('hasChangesToBeSaved', this.haveBeenChanged)
        }
      },
      deep: true
    },
    locationSearch (name) {
      if (!name || name.length < 3) return
      this.debounceEvent(() => {
        this.$store.dispatch('attemptGetLocationByName', name)
          .then(({ data }) => {
            this.locationOptions = data
          })
      }, 900)
    }
  },
  computed: {
    haveBeenChanged () {
      return this.checkIfObjectsHasDifferences(this.formDataInitialState, this.formData) || this.formDataInitialState.imageUrl !== this.formData.imageUrl
    },
    workspaceId () {
      return this.getSelectedWorkspace ? this.selectedWorkspace.lxID : this.$store.getters.getIndividualWorkspace.id
    },
    canAddSkill () {
      return this.skillSearch && this.skillSearch.trim().length > 0 && this.$store.getters.getAvailableSkills.every(item => item.title.toLocaleLowerCase() !== this.skillSearch.toLocaleLowerCase().trim())
    },
    availableSectors () {
      return this.enumActivitySector.map(sector => {
        return {
          text: this.$t(`activity.sector.default:${sector.toLowerCase()}`),
          value: sector
        }
      })
    },
    resizedTitles () {
      if (!this.skillSearch) {
        return this.$store.getters.getAvailableSkills
          .map(skill => ({
            id: skill.id,
            laguagueAlias: skill.language_alias,
            isMain: false,
            title: skill.title.length < 20 ? skill.title : skill.title.slice(0, 20) + '...',
            new: true,
            level: 'interest'
          }))
          .filter((skill) => {
            return this.availableSkills.find(usrSkill => usrSkill.id === skill.id)
          })
      }
      return this.$store.getters.getAvailableSkills.map(skill => ({
        id: skill.id,
        laguagueAlias: skill.language_alias,
        isMain: false,
        title: skill.title.length < 20 ? skill.title : skill.title.slice(0, 20) + '...',
        level: skill.level
      }))
    },
    workspace () {
      return this.$store.getters.getWorkspaceInfos[this.workspaceId]
    },
    isBusiness () {
      return this.workspace && this.workspace.type === 'business'
    }
  },
  methods: {
    addNewSkill () {
      return `${this.$t('global:add')} "${this.skillSearch}"`
    },
    handleSkillAdd () {
      const title = this.skillSearch
      this.$store.dispatch('attemptSaveNewSkill', this.skillSearch)
        .then(({ data }) => {
          const skill = { id: data.id, title }
          this.formData.skills.push(skill)
          this.skillSelected([skill])
          this.$store.dispatch('attemptGetAvailableSkills', { limit: 999999 })
        })
    },
    setup () {
      this.$nextTick(() => {
        this.dirtyForm = false
      })
      if (!this.isBusiness) {
        this.$store.dispatch('attemptGetMyselfSkills')
          .then(response => {
            this.availableSkills = response.data
          })
      }
      this.$store.dispatch('attemptGetWorkspaceById', this.workspaceId)
        .then(() => {
          this.formData = {
            ...this.formData,
            ...this.workspace
          }
          if (this.workspace.location) {
            this.locationOptions.push({ text: this.formData.location, value: this.formData.location })
            this.formData.location = this.workspace.location
          }
          if (!this.isBusiness) {
            const mainSkills = this.formData.skills.filter(skill => !!skill.isMain)

            const skills = []

            mainSkills.forEach(skill => {
              skills.push(this.availableSkills.find(aSkill => aSkill.id === skill.id))
            })

            this.formData.skills = skills
          }
          const links = []
          this.enumWorkspaceLinkType.forEach(linkType => {
            const hasType = this.formData.links.find(links => links.type.toUpperCase() === linkType)
            if (!hasType && linkType === 'URL') return
            links.push({ type: linkType.toLowerCase(), value: hasType ? hasType.value : '' })
          })
          this.formData.links = links

          this.formDataInitialState = this.cloneObj(this.formData)
        })
    },
    async saveProfile () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          const hasErrorElements = document.querySelector('.error--text, .form-input-messages-container')
          const elementPosition = hasErrorElements.offsetTop - 150
          const modalElement = document.querySelector('.v-dialog--active')
          if (modalElement) {
            modalElement.scroll({ top: elementPosition, behavior: 'smooth' })
          } else {
            window.scroll({ top: elementPosition, behavior: 'smooth' })
          }
          hasErrorElements.focus()
        })
        return
      }
      this.isSaving = true

      const parsedPayload = { ...this.formData }
      parsedPayload.links = parsedPayload.links.filter(link => !!link.value)
      parsedPayload.skills = parsedPayload.skills.map(skill => {
        const completeSkill = this.availableSkills.find(aSkill => aSkill.id === skill.id)
        return {
          ...skill,
          ...completeSkill,
          isMain: this.isBusiness || skill.isMain,
          level: completeSkill.level || 'interest'
        }
      })

      await this.$store.dispatch('attemptSaveUserSkills', parsedPayload.skills)

      this.$store.dispatch('attemptSaveWorkspaceSettings', { id: this.workspace.id, payload: parsedPayload })
        .then(() => {
          this.smoothScrollTop()
          this.setup()
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.profile.labels:submit.success')
          })
        })
        .finally(() => {
          this.$emit('hasChangesToBeSaved', false)
          this.formDataInitialState = this.cloneObj(this.formData)
          this.isSaving = false
          this.synchronizeUser().then((userWorkspaces) => {
            const selectedWorkspace = this.selectedWorkspace

            if (selectedWorkspace) {
              const updatedWorkspace = userWorkspaces.find(workspace => workspace.id === this.selectedWorkspace.id)
              if (updatedWorkspace) {
                this.$store.commit('setSelectedWorkspace', updatedWorkspace)
              }
            }
          })
          this.updateComponent('availableWorkspaces')
        })
    },
    addLink () {
      this.formData.links.push({ type: 'url', value: '' })
    },
    removeLink (index) {
      this.formData.links.splice(index, 1)
    },
    changeImage (image) {
      this.$v.formData.imageUrl.$touch()
      this.formData.imageUrl = image
      this.formData.removeImage = null
    },
    removeImage () {
      this.formData.imageUrl = null
      this.formData.removeImage = true
    },
    changeMade () {
      this.dirtyForm = true
    },
    skillSelected (selectedSkills) {
      this.changeMade()

      selectedSkills.forEach((skill) => {
        if (!this.availableSkills.includes((aSkill) => aSkill.id === skill.id)) {
          this.availableSkills.push({ ...skill, level: 'interest', new: true })
        }
      })
    },
    closeConfirmationModal () {
      this.showConfirmationModal = false
    },
    leavePage () {
      this.nextPageFunction()
    }
  },
  created () {
    this.setup()
  },
  beforeRouteLeave (to, from, next) {
    if (this.dirtyForm) {
      this.showConfirmationModal = true
      this.nextPageFunction = next
    } else {
      next()
    }
  }
}
</script>
<template>
  <v-card rounded="1" class="settings-profile" :class="{'business-profile': isBusiness, 'no-picture': getSelectedWorkspace}">
    <v-card-title class="settings-profile--title h7" v-if="!isBusiness">
      {{ $t('settings:profile.title') }}
    </v-card-title>
    <div class="settings-profile--body-wrapper">
      <div class="settings-profile--body-info">
        <section class="settings-profile--info-wrapper">
          <h5 class="settings-profile--subtitle text-left mb-6 h8">
            {{ $t('settings:profile.subtitle.info') }}
          </h5>
          <div class="settings-profile--body-image mb-6" v-if="!getSelectedWorkspace">
            <h5 class="settings-profile--subtitle text-left mb-2 h8">
              {{ $t('settings:profile.subtitle.profile.picture') }}
            </h5>
            <image-upload
              :width="160"
              :height="160"
              rounded
              :aspect-ratio="1"
              :imageBackground="formData.imageUrl || ''"
              :defaultImageBackground="defaultAvatarUrl(isBusiness ? 'business' : 'profile')"
              @updateImage="changeImage"
              @removeImage="removeImage"
              @change="changeMade"
              :validation="$v.formData.imageUrl"
              :hide-validation="!$v.formData.imageUrl.$error && isMobile"
            />
          </div>
          <input-field
            outlined
            v-model="formData.name"
            @focus="$v.formData.name.$touch()"
            :label="$t(`settings.profile.labels:name.${isBusiness ? 'business' : 'personal'}`)"
            :error="isInvalid($v.formData.name)"
            @change="changeMade"
          />
          <input-field
            v-if="!isBusiness"
            outlined
            v-model="formData.lastName"
            @focus="$v.formData.lastName.$touch()"
            :label="$t('settings.profile.labels:lastName')"
            :error="isInvalid($v.formData.lastName)"
            @change="changeMade"
          />
          <input-field
            v-if="!isBusiness"
            outlined
            v-model="formData.position"
            :label="$t('settings.profile.labels:position')"
            @change="changeMade"
          />
          <text-area
            outlined
            v-model="formData.resume"
            @focus="$v.formData.resume.$touch()"
            :label="$t(`settings.profile.labels:resume.${isBusiness ? 'business' : 'personal'}`)"
            :error="isInvalid($v.formData.resume)"
            @change="changeMade"
          />
          <autocomplete
            multiple
            chips
            deletable-chips
            v-model="formData.skills"
            :items="resizedTitles"
            return-object
            :hideValidation="isBusiness"
            item-text="title"
            item-value="id"
            :label="$t(`settings.profile.labels:skills.${isBusiness ? 'business' : 'personal'}`)"
            :search-input.sync="skillSearch"
            :hide-no-data="!skillSearch"
            attach
            @change="skillSelected">
            <template v-slot:no-data>
              <span/>
            </template>
            <v-list-item v-if="canAddSkill" @click="handleSkillAdd" slot="append-item">
              <v-list-item-icon class="mr-2"><v-icon class="primary-medium">mdi-plus</v-icon></v-list-item-icon>
              <v-list-item-content v-text="addNewSkill()"></v-list-item-content>
            </v-list-item>
          </autocomplete>
          <p v-if="isBusiness" class="profile-form--skills_tip">{{ $t('settings.profile.labels:skills.tip') }}</p>
          <autocomplete
            :search-input.sync="locationSearch"
            :items="locationOptions"
            hide-no-data
            small-chips
            outlined
            clearable
            v-model="formData.location"
            prepend-inner-icon="mdi-map-marker"
            :label="$t('settings.profile.labels:location')"
            @change="changeMade"
          />
          <input-field
            v-if="!isBusiness"
            outlined
            v-model="formData.company"
            :label="$t('settings.profile.labels:company')"
            @change="changeMade"
          />
          <select-field
            v-if="isBusiness"
            outlined
            :items="availableSectors"
            v-model="formData.activitySector"
            prepend-inner-icon="mdi-office-building"
            :label="$t('settings.profile.labels:company.sector')"
            @change="changeMade"
          />
        </section>
        <section class="settings-profile--links-wrapper text-left">
          <h5 class="settings-profile--subtitle text-left mb-6 h8">
            {{ $t('settings:profile.subtitle.links') }}
          </h5>
          <template v-for="(link, index) in formData.links">
            <input-field
              :key="link.type"
              outlined
              v-model="link.value"
              :validation="$v.formData.links.$each[index]"
              :label="$t(`settings.profile.labels:links.${link.type}`)"
              @change="changeMade"
            >
              <template v-slot:append>
                <v-btn v-if="link.type === 'url'" class="mt-n2 mr-n3 remove-btn-ripple-background" :ripple="false" text @click="removeLink(index)" color="#1200D3"><b><v-icon>mdi-close</v-icon></b></v-btn>
              </template>
            </input-field>
          </template>
          <v-btn text @click="addLink" color="#1200D3" :ripple="false" class="remove-btn-ripple-background"><b><v-icon class="mb-1">mdi-plus</v-icon>{{ $t('settings.profile.labels:links.add.url') }}</b></v-btn>
        </section>
        <section class="settings-profile--privacy-wrapper text-left">
          <h5 class="settings-profile--subtitle text-left mb-2 h8">
            {{ $t('settings:profile.subtitle.privacy') }}
          </h5>
          <div class="settings-profile--privacy-switch">
            <v-switch class="manage-content--private-switch" v-model="formData.isPrivate"/>
            <div class="manage-content--private-content-warning">
              <p class="text-left pt-2 is-private-label body--1 bold"><b>{{ $t(isBusiness ? 'settings.profile.labels:private.business' : 'settings.profile.labels:private') }}</b></p>
              <p class="text-left is-private-desc body--1">{{ $t(isBusiness ? 'settings.profile.labels:private.warning.business' : 'settings.profile.labels:private.warning') }}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-divider class="mb-5"/>
    <section class="settings-profile--actions-wrapper text-left">
      <v-btn @click="saveProfile" :dark="haveBeenChanged" :disabled="!haveBeenChanged" :loading="isSaving" color="#1200D3"><b>{{ $t('global:save.changes') }}</b></v-btn>
    </section>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="$t('skill.modal.confirm:title')"
      :description="$t('skill.modal.confirm:description')"
      :confirmText="$t('skill.modal.confirm:confirm')"
      @confirm="leavePage"
      :cancelText="$t('skill.modal.confirm:cancel')"
      @cancel="closeConfirmationModal"
    ></confirmation-modal>
  </v-card>
</template>

<style lang="scss">
.settings-profile {
  padding: 0 20px 30px;
  border-radius: 0;
  width: 800px;
  max-width: 100%;

  &.business-profile.v-card {
    padding-top: 40px;
    box-shadow: none;
  }

  &.no-picture {
    .settings-profile--body-wrapper {
      .settings-profile--body-info {
        width: 100%;
      }
    }
  }

  &.v-card.v-sheet {
    box-shadow: none;
  }

  .settings-profile--title {
    @extend .bold;
    padding-left: 0;
  }

  .settings-profile--body-wrapper {
    display: flex;

    .settings-profile--body-info {
      width: 65%;

      .profile-form--skills_tip {
        margin-top: 8px;
        font-size: 11px;
        text-align: left;
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
      .settings-profile--body-info {
        width: 100%;
      }
      .settings-profile--body-image {
        width: 100%;
        margin-bottom: 30px;
        margin-left: 0;

        .relative-container {
          margin: 0 !important;
        }

        .settings-profile--subtitle {
          margin: 0;
        }
      }
    }
  }

  .settings-profile--links-wrapper {
    margin-top: 15px;
  }

  .settings-profile--privacy-wrapper {
    margin-top: 30px;

    .settings-profile--privacy-switch {
      display: flex;

      .manage-content--private-content-warning {
        margin-left: 17px;
      }

      .is-private-label {
        margin-bottom: 8px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .settings-profile--body-wrapper {
      .settings-profile--body-info {
        width: 85%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .settings-profile--body-wrapper {
      .settings-profile--body-info {
        width: 100%;
      }
    }
  }
}
</style>
